<template>
  <div id="nav">
    <router-link to="/">Home</router-link> |
    <router-link to="/cv">Curriculum Vitae</router-link> |
    <router-link to="/uses">Uses</router-link>
  </div>
  <router-view/>
</template>

<style>
* {
  margin: 0;
  padding: 0;
}

.container {
  display: flex;
}

.project-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.item {
  text-align:left;
  flex: 1
}

p {
  text-align: left;
  margin-bottom: 10px;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0px;
}

#nav {
  background: linear-gradient(180deg, #00b09b 0%,#96c93d 100%);
  padding: 20px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
  text-decoration-line: none
}

#nav a:hover {
  text-decoration-line: underline
}

#nav a.router-link-exact-active {
  color: #1c2e40;
}

p {
  text-align: left;
  margin-bottom: 10px;
}
</style>