<template>
  <div>
    <h1>What I use...</h1>

    <div class="container">
      <div>
        <p style="font-weight: bold">Computers</p>

        <ul>
					<li>Asus G16 Strix 13980HX with RTX4070</li>
          <li>MacMini - The i5 6 core version with 32GB RAM and Razer Core X eGPU with an RX580.</li>
          <li>Lenovo X1 Extreme Gen. 2 - i7 9750H with 32GB RAM. Primarily runs some type of Linux with win 11 as dual boot.</li>
          <li>Macbook Pro 13 - i5 7th gen.</li>
          <li>Server - 5950X with 64GB RAM.</li>
        </ul>

        <p></p>

        <p style="font-weight: bold">Software</p>

        <ul>
          <li><a href="https://www.jetbrains.com/idea/">IntelliJ Ultimate</a> for JavaScript, Java, Python and Kotlin development. This is an amazing IDE and love it.</li>
          <li>Switched from VS Code to IntelliJ now for other things like Ansible and Terraform.</li>
          <li><a href="https://www.jetbrains.com/idea/">IntelliJ CE</a> for Java/Terraform/Shell programming at work.</li>
        </ul>

        <p></p>

        <p style="font-weight: bold">Mobile Phones and Tablets</p>
        <ul>
          <li>iPhone 15 Pro Max</li>
          <li>Samsung Galaxy S24 Ultra</li>
          <li>Apple iPad Pro 2018 with Apple Pencil. I use this for sketching out ideas. It is a great device!</li>
          <li>Apple iPad 6th Generation with Apple Pencil.</li>
          <li>Lots of older iPhones (dev devices :))</li>
        </ul>

        <p></p>

        <p style="font-weight: bold">Misc stuff</p>
        <ul>
          <li>Acer Predator 4K 144hz Monitor.</li>
          <li>Logitech G502 Mouse.</li>
          <li>Kemove Mechanical Keyboard (Browns)</li>
          <li>Logitech Streamcam</li>
          <li>EPOS H6Pro headphones</li>
          <li>Generic monitor lamp.</li>
          <li><a href="https://www.amazon.de/gp/product/B00B99C0OA">Topstar Ergonomic Office Chair</a> - I got it in white as they had a warehouse deal for half the price. It turned out to be brand new anyway :)</li>
          <li>Raspberry Pis: 1, 4 & Pi Zero W. Used for various projects, like home automation, Nexus etc.</li>
        </ul>

        <p>
        </p>

        <PageFooter footer-align="left"/>
      </div>
    </div>

  </div>
</template>

<script>
  import PageFooter from '../components/PageFooter.vue'

  export default {
    name: "UsesView",
    components: {
        PageFooter
    }
  }
</script>

<style scoped>
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container div {
    width: 600px;
    height: 400px;
    text-align: left
  }

  p {
    text-align: left;
    margin-bottom: 10px;
  }
</style>
