<template>
  <div>
    <h1>Curriculum Vitae</h1>
    
    <div class="container">
      <div>
        <p style="font-weight: bold">Current Skillset</p>
        <p>
          These are my current up-to-date competencies:
        </p>
        <p>
          Saltstack, Terraform, Ansible, Bash Scripting, Python, Java, ElasticStack, Kubernetes, Docker and Postfix.
        </p>
        <p>
          I have experience with IBM Cloud, AWS and Azure.
        </p>
        <p>
          I am currently using Kanban.
        </p>
        <p style="font-weight: bold">Education</p>
        <p>
          I earned the following City &amp; Guilds qualifications during my time at college:
        </p>
        <p>       
            Pascal, BASIC, Electronic Systems and Industrial Equipment.
        </p>
        <p>
          During my school years I earned GCSE qualifications in: Maths, English, French, Computer Science, Metalwork, English Literature and Physics.
        </p>
        <PageFooter footer-align="left"></PageFooter>
      </div>
    </div>
  </div>
</template>

<script>
import PageFooter from '../components/PageFooter'
export default {
  name: "CvView",
  components: {
    PageFooter
  }
};
</script>

<style scoped>
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container div {
    width: 600px;
    height: 600px;
  }
</style>