import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue';
import CvView from '../views/CvView.vue';
import UsesView from '../views/UsesView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cv',
    name: 'cv',
    component: CvView
  },
  {
    path: '/uses',
    name: 'uses',
    component: UsesView
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
