<template>
  <div>
    <h1>Heidi Harding</h1>
    
    <img src="../assets/circle-photo.png"/>
    
    <div class="container">
      <div>
        <p style="font-weight: bold">About me</p>
        <p>
          I am a Senior Platform Engineer who works with both development and infrastructure technologies in the Data Engineering field.
        </p>

        <p>
          I am incredibly enthusiastic and curious about all things tech. This extends into my free time and I am constantly learning.
        </p>

        <p>
          I am also very passionate about photography and mountain biking and enjoy taking pictures of landscapes and nature. You can see examples of my work here: <a href="https://500px.com/adelheid_de">500px</a>
        </p>
        <PageFooter footer-align="left"></PageFooter>
      </div>
    </div>
  </div>
</template>

<script>
import PageFooter from '../components/PageFooter.vue'
export default {
  name: 'HomeView',
  components: {
    PageFooter
  }
}
</script>

<style scoped>
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .container div {
    width: 600px;
    height: 400px;
  }

  p {
    text-align: left;
    margin-bottom: 10px;
  }

</style>
