<template>
  <div class="page-footer" :style="{ textAlign: footerAlign }">
    
  &copy;2020 <a rel="me" href="https://fosstodon.org/@mietzmietz">Heidi Harding</a>. Created with Vue.
  </div>
</template>

<script>
export default {
  name: "page-footer",
  props: ["footerAlign"]
}
</script>

<style scoped>
  .page-footer {
    font-size: x-small;
  }
</style>
